import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function AthleticsPage() {

    return(
        <div className='project-format'>
            <div className='project-title'>
                Athletics Page - Work in Progress
            </div>
        </div>
    );
}

export default AthleticsPage;